import {ArrowRight} from "react-feather";

export default function Timetable({timetable, login}) {
    return (
        <div>
            <h3 className="text-2xl text-unibaRed font-uksans border-b border-b-unibaRed mb-4 py-2">Rozvrh hodín</h3>
            <table className="table-auto w-full mb-2">
                <tbody>
                {timetable.map((lesson) => {
                    return (
                        <tr className="border-b border-b-gray-200 hover:bg-gray-50" key={`${lesson.day}-${lesson.start}-${lesson.name}`}>
                            <td className="px-4 py-3 text-center">{lesson.day}</td>
                            <td className="px-4 py-3 text-center">{lesson.timeFrom}</td>
                            <td className="px-4 py-3 text-center">{lesson.timeUntil}</td>
                            <td className="px-4 py-3 ">{lesson.subject}</td>
                            <td className="px-4 py-3 text-center">{lesson.form}</td>
                            <td className="px-4 py-3 text-center">{lesson.room}</td>
                            <td className="px-4 py-3 ">{lesson.teacher}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <a href={`https://candle.fmph.uniba.sk/ucitelia/${login}`} className="pl-0 px-4 py-2 text-unibaRed inline-flex items-center gap-2 hover:underline hover:gap-3 underline-offset-4 transition-all">
                Zobraziť v Candle
                <ArrowRight/>
            </a>
        </div>
    );
}