import { ReactComponent as Logo } from '../img/logo.svg';
import {Search} from "react-feather";

export default function Header() {
    return (
        <header>
            <div className="bg-unibaRed bg-center bg-no-repeat text-white md:h-[133px] flex flex-col justify-center">
                <div className="ct w-full flex flex-col md:flex-row items-center justify-between gap-6 py-4 md:py-0">
                    <a href="https://www.fmph.uniba.sk/"><Logo className="max-w-full" height="95"/></a>
                    <div className="relative opacity-80 focus-within:opacity-100">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-600">
                            <Search/>
                        </span>
                        <input name="search" className="form-input p-2 pl-10 text-gray-950 rounded w-80 focus:shadow-xl transition-all" type="text" placeholder="Hľadať osobu..."/>
                    </div>
                </div>
            </div>
        </header>


    )
}