import { ReactComponent as NavIcon } from '../img/nav-icon.svg';
import {ChevronDown, Lock} from "react-feather";

const links = [
    {link: "https://uniba.sk", title:"Univerzita Komenského"},
    {link: "https://www.fmed.uniba.sk/", title:"Lekárska fakulta"},
    {link: "https://www.flaw.uniba.sk/", title:"Právnická fakulta"},
    {link: "https://www.fphil.uniba.sk/", title:"Filozofická fakulta"},
    {link: "https://www.fns.uniba.sk/", title:"Prírodovedecká fakulta"},
    {link: "https://www.fedu.uniba.sk/", title:"Pedagogická fakulta"},
    {link: "https://www.fpharm.uniba.sk/", title:"Farmaceutická fakulta"},
    {link: "https://www.fsport.uniba.sk/", title:"Fakulta telesnej výchovy a športu"},
    {link: "https://www.jfmed.uniba.sk/", title:"Jesseniova lekárska fakulta"},
    {link: "https://www.fmph.uniba.sk/", title:"Fakulta matematiky, fyziky a informatiky"},
    {link: "https://www.frcth.uniba.sk/", title:"Rímskokatolícka cyrilometodská bohoslovecká fakulta"},
    {link: "https://www.fevth.uniba.sk/", title:"Evanjelická bohoslovecká fakulta"},
    {link: "https://www.fm.uniba.sk/", title:"Fakulta managementu"},
    {link: "https://www.fses.uniba.sk/", title:"Fakulta sociálnych a ekonomických vied"}
]

export default function UniversalNav () {
    return (
        <div className="bg-unibaRed-dark">
            <div className="ct flex flex-row items-center justify-between text-white">
                <div className="group relativem">
                    <span className="inline-flex flex-row items-center gap-1 text-white p-1 opacity-80 group-hover:bg-unibaRed-darker group-hover:opacity-100 transition-all">
                        <NavIcon className="h-4"/>
                        <ChevronDown/>
                    </span>
                    <div className="absolute flex flex-col bg-black/50 z-50 backdrop-blur-lg text-white max-w-xs group-hover:visible invisible shadow shadow-xl">
                        {links.map((item) =>
                            <a className="px-2 py-1" href={item.link}>{item.title}</a>
                        )}
                    </div>
                </div>
                <div className="flex flex-row items-center text-white">
                    <a href="#" className="inline-flex flex-row items-center gap-2 text-white py-1 px-2 hover:bg-unibaRed-darker opacity-80 hover:opacity-100 transition-all">
                        <Lock size="16"/>
                        Prihlásiť sa
                    </a>
                </div>
            </div>
        </div>
    )
}