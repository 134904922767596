export default function Footer() {
    return (
        <footer className="">
            <div className="bg-oil text-white">
                <div className="ct py-2">
                    Copyright © UK
                </div>
            </div>
        </footer>
    )
}