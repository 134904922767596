import {Link} from "react-router-dom";


export default function Index () {
    return (
        <div className="ct">
            <Link to="vinar1">vinar1</Link><br/>
            <Link to="brejova2">brejova2</Link><br/>
            <Link to="sevcovic1">sevcovic1</Link><br/>
            <Link to="skrlec1">skrlec1</Link><br/>

        </div>
    )
}
