import Header from "../components/Header";
import Footer from "../components/Footer";
import {Outlet} from "react-router-dom";
import UniversalNav from "../components/UniversalNav";

function App() {
    return (
        <div className="flex flex-col h-screen">
            <UniversalNav/>
            <Header/>
            <div className="grow">
                <Outlet/>
            </div>
            <Footer/>
        </div>
    )
}

export default App;
