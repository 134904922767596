import {ArrowRight} from "react-feather";

export default function Publications({publications}) {
    return (
        <div>
            <h3 className="text-2xl text-unibaRed font-uksans border-b border-b-unibaRed mb-4 py-2">Vybrané publikácie</h3>

            {publications.map(item => (
                <section className="mb-6 space-y-1">
                    <h4 className="text-lg font-bold">{item.name}</h4>
                    <p>{item.authors}</p>
                    <p className="italic text-sm">{item.location}</p>
                </section>
            ))}

            <a href={`https://candle.fmph.uniba.sk/ucitelia/$`} className="pl-0 px-4 py-2 text-unibaRed inline-flex items-center gap-2 hover:underline hover:gap-3 underline-offset-4 transition-all">
                Zoznam všetkých evidovaných publikácií
                <ArrowRight/>
            </a>
        </div>
    );
}