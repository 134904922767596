import {Link, useAsyncError, useRouteError} from "react-router-dom";
import {AlertOctagon} from "react-feather";

export default function ErrorPage() {
    const error = useRouteError();
    const asyncError = useAsyncError();

    return (
        <div className="">
            <div className="ct flex flex-col items-center gap-8 py-8">
                <AlertOctagon size={64} className="stroke-unibaRed"/>
                <h1 className="text-2xl">{asyncError.message || error.statusText || error.message}</h1>
                <Link to="/" className="bg-unibaRed hover:bg-unibaRed-light active:bg-unibaRed-dark rounded text-white px-4 py-2">Späť na úvod</Link>
            </div>
        </div>
    )
}