const endpoint = "/api"

function handleErrors(res) {
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    return res;
}

export async function getPerson(login) {
    return fetch(`${endpoint}/${login}`)
        .then(handleErrors)
        .then(res => res.json());
}
