import {Globe, Home, Mail, Phone} from "react-feather";
import {Suspense, useState} from "react";
import {getPerson} from "../api/people";
import {Await, defer, useLoaderData} from "react-router-dom";
import Timetable from "../components/Timetable";
import Publications from "../components/Publications";
import ErrorPage from "./ErrorPage";
import Loading from "../components/Loading";

export async function loader({params}) {
    return defer({person: getPerson(params.login)});
}

function TabBar(props) {
    const [active, setActive] = useState(props.children[0].props.label);

    return (
        <div>
            <div className="bg-gray-100">
                <div className="ct flex flex-row overflow-x-auto">
                    {props.children.map((child) => {
                        if (!child) return null;
                        const {label} = child.props;

                        return (
                            <button key={`tab-${label}`} onClick={() => setActive(label)} className={"cursor-pointer whitespace-nowrap break-keep px-3 py-2 hover:bg-gray-200 transition-all border-b-4 " + (label === active ? "border-b-unibaRed" : "border-b-transparent")}>
                                {label}
                            </button>
                        );
                    })}
                </div>
            </div>
            <div className="ct py-4">
                {props.children.map((child) => {
                    if (!child) return null;
                    const {label} = child.props;
                    return label === active ? child : null;
                })}
            </div>
        </div>
    )
}

function ContactRow(props) {
    if (!(props.list.length > 0)) {
        return null;
    }

    return (
        <div className="flex flex-row gap-2">
            <props.icon/>
            <ul className="space-y-2">
                {props.list.map(item => (<li>
                    {props.link ? (
                        <a href={`${typeof props.link === 'string' ? props.link : ''}${item}`} className="hover:underline underline-offset-4">{item}</a>
                    ) : item}
                </li>))}
            </ul>
        </div>
    )
}

function ContactSubtitle({workplaces}) {
    if (!(workplaces.length > 0)) {
        return null;
    }

    const wp = workplaces[0];
    let position = wp.uni;

    if (wp.positions) {
        position = wp.positions;
        if (wp.department) {
            position += `, ${wp.department}`;
        }
    } else if (wp.department) {
        position = wp.department;
    } else if (wp.faculty) {
        position = wp.faculty;
    }

    return (
        <h2 className="text-xl text-gray-900 font-uksans">
            {position}
        </h2>
    )
}

export default function Person() {
    const {person} = useLoaderData();

    return (
        <Suspense fallback={<Loading/>}>
            <Await resolve={person} errorElement={<ErrorPage/>}>
                {(person) => (
                    <>
                        <div className="bg-gray-100">
                            <div className="ct py-4 flex flex-col md:flex-row gap-8">
                                {person.photo ? <img src={person.photo} className="md:h-64 order-last md:order-first"/> : null}
                                <div className="">
                                    <h1 className="text-3xl text-unibaRed font-uksans">{
                                        [
                                            person.titleBefore,
                                            person.firstName,
                                            person.middleName,
                                            person.lastName,
                                            person.titleAfter,
                                        ].join(" ")}
                                    </h1>
                                    {person.workplace && <ContactSubtitle workplaces={person.workplace} />}
                                    <div className="pt-6 flex flex-col gap-2">
                                        {person.room && <ContactRow icon={Home} list={person.room}/>}
                                        {person.email && <ContactRow icon={Mail} list={person.email} link="mailto:"/>}
                                        {person.phone && <ContactRow icon={Phone} list={person.phone}/>}
                                        {person.link && <ContactRow icon={Globe} list={person.link} link/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TabBar>
                            <div key="home" label="Prehľad">Tu bude prehlad uvazkov, niekedy mozno nejake custom texty</div>
                            {person.candle && <Timetable key="teaching" label="Výuka" timetable={person.candle} login={person.login}/>}
                            {person.publications && <Publications key="pub" label="Publikácie" publications={person.publications}/>}
                        </TabBar>
                    </>
                )}
            </Await>
        </Suspense>
    )
}